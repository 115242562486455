import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const ClearButton = (props) => {
  return (
    <button className="clear-button" onClick={props.onClick}>
      <FontAwesomeIcon icon={icon({ name: 'xmark' })} />
    </button>
  );
};

export default ClearButton;
